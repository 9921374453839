<template>
	<div>
		<div class="content">
			<div class="mb20 pageTitle"><b>提现卡</b></div>
			<div>
				<el-form class="form" label-width="120px" v-loading="loading">
					<el-form-item label="姓名:">
						<el-input type="text" placeholder="请输入姓名" v-model="bankObj.name" clearable></el-input>
					</el-form-item>
					<el-form-item label="开户行:">
						<el-input type="text" placeholder="请输入开户行" v-model="bankObj.bank" clearable></el-input>
					</el-form-item>
					<el-form-item label="银行卡号:">
						<el-input type="text" placeholder="请输入银行卡号" v-model="bankObj.card" clearable></el-input>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="onSubmit">确定</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,
		isIncludeHanzi,
		isAllNumber
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			let userinfo = local.get("bpcUserinfo");
			let showBank = 1 == userinfo.mention_card;
			return {
				loading: false,
				bankObj: {
					name: "",
					bank: "",
					card: ""
				},
				showBank: showBank,
				userinfo: userinfo
			};
		},
		created() {
			this.infoTotal();
			if (1 == this.userinfo.mention_card) {
				this.mentionCardInfo()
			}
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					let val = Math.floor(((value * 1) * 100).toPrecision(12))
					return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			infoTotal(){
				this.$api.infoTotal({}).then(res=>{
					if(res.status == 1){
						this.userinfo = res.data
						this.$local.set('bpcUserinfo',res.data);
						this.showBank = 1 == this.userinfo.mention_card;
					}
				})
			},
			mentionCardInfo() {
				this.$api.mentionCardInfo({}).then(res => {
					if (res.status == 1) {
						this.bankObj = res.data
					}
				})
			},
			addMentionCard: function() {
				var t = this;
				if (!(0, isEmpty)(this.bankObj.name, "姓名不能为空") && !(0, isEmpty)(this.bankObj.bank, "开户行不能为空") && !(0,
						isEmpty)(this.bankObj.card, "卡号不能为空"))
					if ((0, isAllNumber)(this.bankObj.card,
							"卡号只能为数字")) {
						var data = {
							name: this.bankObj.name,
							card: this.bankObj.card,
							bank: this.bankObj.bank
						};
						this.$api.addMentionCard(data).then(res => {
							this.loading = false
							if (res.status == 1) {
								this.userinfo.mention_card = 1
								local.set("bpcUserinfo", this.userinfo);
								this.$message({
									type: 'success',
									message: res.msg
								})
								this.$confirm('提现卡绑定成功，现在去提现吗?', '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								}).then(() => {
									this.$router.push({
										path: '/withdraw'
									})
								}).catch(() => {});
							}
						}).catch(error => {
							this.loading = false
						})
					} else this.$message({
						type: 'error',
						message: '输入的银行卡号不合法'
					})
			},
			editMentionCard() {
				var t = this;
				if (!(0, isEmpty)(this.bankObj.name, "姓名不能为空") && !(0, isEmpty)(this.bankObj.bank, "开户行不能为空") && !(0, isEmpty)(this.bankObj.card, "卡号不能为空"))
					if ((0, isAllNumber)(this.bankObj.card,
							"输入的银行卡号不合法")) {
						var data = {
							id: this.bankObj.id,
							name: this.bankObj.name,
							card: this.bankObj.card,
							bank: this.bankObj.bank
						};
						this.$api.editMentionCard(data).then(res => {
							if (res.status == 1) {
								this.$message({
									type: 'success',
									message: res.msg
								})
							}
						})
					} else this.$message({
						type: 'error',
						message: '输入的银行卡号不合法'
					})
			},
			onSubmit() {
				0 == this.userinfo.mention_card ? this.addMentionCard() : 1 == this.userinfo.mention_card && this.editMentionCard()
			}
		},
		watch: {},
	};
</script>

<style lang="scss">
	@import '@/style/variables.scss';

	.content {
		.pageTitle {
			background-color: $navSubColor;
			padding: 10px;
		}

		.tips {
			width: 460px;
		}

		.form {
			width: 460px;
		}
	}
</style>
